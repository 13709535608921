import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./components/App";
import { webLightTheme, webDarkTheme } from "@fluentui/react-components";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { getExtensions } from "../api/MiddleTier";
import { dialogFallback } from "../helpers/fallbackauthdialog";

/* global document, Office, OfficeRuntime, module, require, process, console */

const rootElement: HTMLElement = document.getElementById("container");
const root = createRoot(rootElement);
let retryGetMiddletierToken = 0;

const getExtensionsAndRender = () => {
  // Initialize translations using Outlook display language
  i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init({
      fallbackLng: {
        "de-DE": ["de-AT"],
        "de-CH": ["de-AT"],
        default: ["en-GB"],
      },
      lng: Office.context.displayLanguage,
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      supportedLngs: [
        "en-GB",
        "sq-AL",
        "bg-BG",
        "hr-HR",
        "cs-CZ",
        "ka-GE",
        "et-EE",
        "de-AT",
        "hu-HU",
        "lv-LV",
        "lt-LT",
        "mk-MK",
        "pl-PL",
        "ro-RO",
        "ru-RU",
        "sr-Latn-RS",
        "sk-SK",
        "sl-SI",
        "tr-TR",
        "uk-UA",
      ],
      backend: {
        loadPath: `${process.env.REACT_APP_PHRASE_BASE_URL}/{{lng}}.json`,
      },
    });

  const officeTheme =
    !Office.context?.officeTheme ||
    Office.context.officeTheme.bodyBackgroundColor?.toLowerCase() === "#ffffff" ||
    Office.context.officeTheme.controlBackgroundColor?.toLowerCase() === "#ffffff"
      ? webLightTheme
      : webDarkTheme;

  // Render the application after getting the extensions
  OfficeRuntime.auth
    .getAccessToken({
      allowSignInPrompt: true,
      allowConsentPrompt: true,
      forMSGraphAccess: false,
    })
    .then((middletierToken) => {
      getExtensions(middletierToken, {
        messageId: Office.context.mailbox.convertToRestId(
          Office.context.mailbox.item.itemId,
          Office.MailboxEnums.RestVersion.v2_0
        ),
      })
        .then((response) => {
          let res = response.data;

          // AAD errors are returned to the client with HTTP code 200, so they do not trigger
          // the catch block below.
          if (res.error) {
            handleAADErrors(res, (result) => {
              let resultData = result.data;
              root.render(<App theme={officeTheme} claimLog={resultData.claimID && resultData} />);
            });
          } else {
            root.render(<App theme={officeTheme} claimLog={res.claimID && res} />);
          }
        })
        .catch((error) => {
          console.error(error);
          root.render(<App theme={officeTheme} />);
        });
    })
    .catch(() => {
      root.render(<App theme={officeTheme} />);
    });
};

const handleAADErrors = (response: any, callback: any): void => {
  // On rare occasions the middle tier token is unexpired when Office validates it,
  // but expires by the time it is sent to AAD for exchange. AAD will respond
  // with "The provided value for the 'assertion' is not valid. The assertion has expired."
  // Retry the call of getAccessToken (no more than once). This time Office will return a
  // new unexpired middle tier token.
  if (response.error_description.indexOf("AADSTS500133") !== -1 && retryGetMiddletierToken <= 0) {
    retryGetMiddletierToken++;
    getExtensionsAndRender();
  } else {
    dialogFallback(callback);
  }
};

/* Render application after Office initializes */
Office.onReady(getExtensionsAndRender);

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root.render(NextApp);
  });
}
