import * as React from "react";
import Header from "./Header";
import HeroList, { HeroListItem } from "./HeroList";
import ClaimCreation from "./ClaimCreation";
import { FluentProvider, makeStyles, Theme } from "@fluentui/react-components";
import { Compose24Regular, DatabasePerson24Regular, DatabaseSearch24Regular } from "@fluentui/react-icons";
import { useTranslation } from "react-i18next";
import { ClaimLog } from "../../api/model/ClaimLog";
import { ClaimCreationInfo } from "./ClaimCreatedInfo";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "../insights";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
});

interface AppProps {
  theme: Theme;
  claimLog?: ClaimLog;
}

const App = ({ theme, claimLog }: AppProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const [forceClaimCreation, setForceClaimCreation] = React.useState<boolean>(false);

  // The list items are static and won't change at runtime,
  // so this should be an ordinary const, not a part of state.
  const listItems: HeroListItem[] = [
    {
      icon: <Compose24Regular />,
      primaryText: t("oac.expanation.item1"),
    },
    {
      icon: <DatabasePerson24Regular />,
      primaryText: t("oac.expanation.item2"),
    },
    {
      icon: <DatabaseSearch24Regular />,
      primaryText: t("oac.expanation.item3"),
    },
  ];

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <FluentProvider theme={theme}>
        {claimLog && !forceClaimCreation ? (
          <ClaimCreationInfo claimLog={claimLog} createClaim={() => setForceClaimCreation(true)} />
        ) : null}
        {!claimLog || forceClaimCreation ? (
          <div className={styles.root}>
            <Header logo="assets/logo-filled.png" title={t("oac.appLogo.title")} message="Claims" />
            <HeroList message={t("oac.form.title")} items={listItems} />
            <ClaimCreation />
          </div>
        ) : null}
      </FluentProvider>
    </AppInsightsContext.Provider>
  );
};

export default App;
