/* global process */

import axios from "axios";
import { ExtensionsFilter } from "./model/ExtensionsFilter";

export const getClient = async (middletierToken: string, formData: FormData) =>
  axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_API}/client`,
    headers: {
      "X-Custom-Authorization": `Bearer ${middletierToken}`,
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });

export const searchClients = async (middletierToken: string, search: string, skip?: number) =>
  axios({
    method: "GET",
    url: `${process.env.REACT_APP_BASE_URL_API}/search?term=${search}${skip ? `&skip=${skip}` : ""}`,
    headers: {
      "X-Custom-Authorization": `Bearer ${middletierToken}`,
    },
  });

export const getExtensions = async (middletierToken: string, filter: ExtensionsFilter) =>
  axios({
    method: "POST",
    url: `${process.env.REACT_APP_BASE_URL_API}/extensions`,
    headers: {
      "X-Custom-Authorization": `Bearer ${middletierToken}`,
      "Content-Type": "application/json",
    },
    data: filter,
  });
