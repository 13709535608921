/* global Office, OfficeRuntime, process,  window */

import * as React from "react";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Field,
  tokens,
  makeStyles,
  SelectionEvents,
  OptionOnSelectData,
  Spinner,
} from "@fluentui/react-components";
import { Open16Regular } from "@fluentui/react-icons";
import { getClient } from "../../api/MiddleTier";
import { ClientSearch } from "./ClientSearch";
import { Client } from "../models/Client";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/dateFormat";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

const useStyles = makeStyles({
  instructions: {
    fontWeight: tokens.fontWeightSemibold,
    marginTop: "20px",
    marginBottom: "10px",
  },
  error: {
    fontWeight: tokens.fontWeightBold,
    color: tokens.colorStatusDangerBorder1,
    marginBottom: "10px",
  },
  warning: {
    fontWeight: tokens.fontWeightSemibold,
    color: tokens.colorStatusWarningForeground1,
    marginBottom: "10px",
  },
  textPromptAndInsertion: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  textAreaField: {
    marginLeft: "20px",
    marginTop: "30px",
    marginBottom: "20px",
    marginRight: "20px",
  },
});

interface PolicyClient {
  clientName: string;
  clientId: string;
}

const ClaimCreation: React.FC = () => {
  const { t } = useTranslation();
  const appInsights = useAppInsightsContext();
  const [clientName, setClientName] = useState<string | undefined>(undefined);
  const [clientID, setClientID] = useState<string | undefined>(undefined);
  const [isOrganization, setIsOrganization] = useState<boolean>(true);
  const [clients, setClients] = useState<Client[]>([]);
  const [claimCreation, setClaimCreation] = useState<boolean>(false);
  const [fetchingClients, setFetchingClients] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<number | undefined>(undefined);
  const [internalNumber, setInternalNumber] = useState<string | undefined>(undefined);
  const [triggerError, setTriggerError] = useState<string | undefined>(undefined);
  const [policyClient, setPolicyClient] = useState<PolicyClient | undefined>(undefined);
  const [differentPolicyClientWarning, setDifferentPolicyClientWarning] = useState<boolean>(false);

  const fetchClients = async () => {
    try {
      setError(undefined);
      let middletierToken: string = await OfficeRuntime.auth.getAccessToken({
        allowSignInPrompt: true,
        allowConsentPrompt: true,
        forMSGraphAccess: false,
      });
      const formData = new FormData();
      formData.append("emailAddress", Office.context.mailbox.item.from.emailAddress);
      let response: any = await getClient(middletierToken, formData);
      if (!response) {
        throw new Error("Middle tier didn't respond");
      } else if (response.claims) {
        // Microsoft Graph requires an additional form of authentication. Have the Office host
        // get a new token using the Claims string, which tells AAD to prompt the user for all
        // required forms of authentication.
        let mfaMiddletierToken: string = await OfficeRuntime.auth.getAccessToken({
          authChallenge: response.claims,
        });
        response = await getClient(mfaMiddletierToken, formData);
      }

      setFetchingClients(false);
      let res: Client[] = response.data;
      setClients(res);
      if (res.length === 0) return;
      setClientName(res[0].clientName);
      setClientID(`${res[0].clientId}`);
      setIsOrganization(res[0].isOrganization);
      setCountry(res[0].countryCodeId);
      setInternalNumber(`${res[0].internalNumber}`);
    } catch (err: any) {
      setFetchingClients(false);
      setError(err.message);
    }
  };

  useEffect(() => {
    // Get the client list
    fetchClients();
  }, []);

  useEffect(() => {
    policyClient && setDifferentPolicyClientWarning(policyClient.clientId !== clientID);
  }, [policyClient, clientID]);

  const openGosClaims = () => {
    const messageId = Office.context.mailbox.item.internetMessageId.toLowerCase().split("@")[0].substring(1);
    window.open(
      `${process.env.REACT_APP_BASE_URL_GOS}/claim?isNewClaim=true&${
        isOrganization ? "organisationoid" : "partneroid"
      }=${clientID}&messageid=${messageId}&mailRestId=${Office.context.mailbox.convertToRestId(
        Office.context.mailbox.item.itemId,
        Office.MailboxEnums.RestVersion.v2_0
      )}`, // Remark: Consider using different method for mobile devices since convertToRestId is not supported
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  const handleClaimCreate = () => {
    setClaimCreation(true);
    const formData = new FormData();
    const messageId = Office.context.mailbox.item.internetMessageId.toLowerCase().split("@")[0].substring(1);
    formData.append("mailID", messageId);
    formData.append("subject", Office.context.mailbox.item.subject);
    formData.append("date", formatDate(Office.context.mailbox.item.dateTimeCreated));
    country && formData.append("clientCountry", `${country}`);
    internalNumber && formData.append("clientInternalNumber", internalNumber);
    Office.context.mailbox.item.body.getAsync(Office.CoercionType.Text, async (mailResult) => {
      if (mailResult.status !== Office.AsyncResultStatus.Succeeded) {
        appInsights.trackException({ error: new Error("Failed to get mail body.") });
        setClaimCreation(false);
      } else {
        formData.append("body", mailResult.value);
        const attachments = Office.context.mailbox.item.attachments;
        let attachmentCount = 0;
        let attachmentsTotalSize = 0;
        if (Office.context.mailbox.item.attachments !== undefined) {
          const attachmentDataReaders: Promise<boolean>[] = [];
          for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];
            if (attachment.isInline || !attachment.id) continue;

            attachmentDataReaders.push(
              new Promise((resolve, reject) => {
                Office.context.mailbox.item.getAttachmentContentAsync(attachment.id, async (contentResult) => {
                  if (contentResult.status !== Office.AsyncResultStatus.Succeeded) {
                    appInsights.trackException({ error: new Error("Failed to get attachment content.") });
                    reject(false);
                  } else {
                    const attachmentData = contentResult.value;
                    const attachmentBlob = new Blob([attachmentData.content], { type: attachmentData.format });
                    attachmentsTotalSize += attachmentBlob.size;
                    // 29 MB is the maximum size of attachments that can be sent to the middle tier because of the SWA request size limit of 30 MB
                    if (attachmentsTotalSize < 30408704) {
                      formData.append(`attachment${attachmentCount++}`, attachmentBlob, attachment.name);
                    } else {
                      appInsights.trackException({ error: new Error("Attachments size exceeds the limit.") });
                    }
                  }
                  resolve(true);
                });
              })
            );
          }
          await Promise.all(attachmentDataReaders);
        }

        let middletierToken: string = await OfficeRuntime.auth.getAccessToken({
          allowSignInPrompt: true,
          allowConsentPrompt: true,
          forMSGraphAccess: false,
        });
        try {
          const parseMailResult = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_BASE_URL_API}/trigger`,
            headers: {
              "X-Custom-Authorization": `Bearer ${middletierToken}`,
              "Content-Type": "multipart/form-data",
            },
            data: formData,
          });
          const response = parseMailResult.data;
          const clientData = response.slice(12).split(", clientPartnerId: ");
          if (clientData[1] !== "None" && clientID !== clientData[1]) {
            setPolicyClient({ clientName: clientData[0], clientId: clientData[1] });
          } else {
            openGosClaims();
          }
        } catch (error: any) {
          appInsights.trackException({ error });
          setTriggerError(error.message);
        } finally {
          setClaimCreation(false);
        }
      }
    });
  };

  const styles = useStyles();

  return (
    <div className={styles.textPromptAndInsertion}>
      {fetchingClients ? (
        <Spinner size="large" />
      ) : (
        <>
          <Field
            className={styles.textAreaField}
            size="large"
            label={error || !clientName ? t("oac.client.error") : t("oac.client.result", { clientName })}
          >
            <ClientSearch
              options={clients}
              onOptionSelect={(__event: SelectionEvents, data: OptionOnSelectData) => {
                setClientName(data.optionText);
                const selectedClient = JSON.parse(data.optionValue);
                setClientID(selectedClient?.clientId);
                setIsOrganization(selectedClient?.isOrganization);
                setCountry(selectedClient.countryCodeId);
                setInternalNumber(selectedClient.internalNumber);
                setError(undefined);
              }}
              disabled={claimCreation || !!triggerError}
            />
          </Field>
          {!triggerError && !differentPolicyClientWarning ? (
            <Field className={styles.instructions}>
              {claimCreation ? t("oac.createClaim.actionProgress") : t("oac.createClaim.description")}
            </Field>
          ) : null}
          {triggerError ? (
            <Field className={styles.error}>{t("auth.error.InternalError.txt")}</Field>
          ) : differentPolicyClientWarning ? (
            <>
              <Field className={styles.warning}>
                {t("oac.policyClient.warning", { policyClientName: policyClient.clientName })}
              </Field>
              <Button appearance="primary" size="large" icon={<Open16Regular />} onClick={() => openGosClaims()}>
                {t("oac.createAnyway.action")}
              </Button>
            </>
          ) : (
            <Button
              appearance="primary"
              disabled={claimCreation || !clientName}
              size="large"
              icon={claimCreation ? <Spinner size="extra-tiny" appearance="inverted" /> : <Open16Regular />}
              onClick={handleClaimCreate}
            >
              {t("oac.createClaim.action")}
            </Button>
          )}
        </>
      )}
    </div>
  );
};

export default ClaimCreation;
