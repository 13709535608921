/* global window, process, Office */

import * as React from "react";
import { ClaimLog } from "../../api/model/ClaimLog";
import { tokens, makeStyles, Button } from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import { Add16Regular, CalendarDay24Regular, Open16Regular, Person24Regular } from "@fluentui/react-icons";
import Header from "./Header";

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
  },
  list: {
    marginTop: "20px",
  },
  listItem: {
    paddingBottom: "20px",
    display: "flex",
  },
  icon: {
    marginRight: "10px",
  },
  itemText: {
    fontSize: tokens.fontSizeBase300,
    fontColor: tokens.colorNeutralBackgroundStatic,
  },
  welcome__main: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    fontSize: tokens.fontSizeBase500,
    fontColor: tokens.colorNeutralBackgroundStatic,
    fontWeight: tokens.fontWeightRegular,
    paddingLeft: "20px",
    paddingRight: "10px",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    rowGap: "16px",
    marginLeft: "8px",
    marginRight: "8px",
  },
  actionButton: {
    width: "100%",
  },
});

interface ClaimCreationInfoProps {
  claimLog: ClaimLog;
  createClaim: () => void;
}

export const ClaimCreationInfo = ({ claimLog, createClaim }: ClaimCreationInfoProps) => {
  const styles = useStyles();
  const { t } = useTranslation();
  const isClaimCreatedByCurrentUser = Office.context.mailbox.userProfile.displayName === claimLog.createdBy;

  return (
    <div className={styles.root}>
      <Header logo="assets/logo-filled.png" title={t("oac.appLogo.title")} message="Claims" />
      <div className={styles.welcome__main}>
        <h2 className={styles.message}>
          {isClaimCreatedByCurrentUser ? t("oac.youCreatedClaim.title") : t("oac.createdClaim.title")}
        </h2>
        <ul className={styles.list}>
          {isClaimCreatedByCurrentUser ? null : (
            <li className={styles.listItem} key={0}>
              <i className={styles.icon}>
                <Person24Regular />
              </i>
              <span className={styles.itemText}>{claimLog.createdBy}</span>
            </li>
          )}
          <li className={styles.listItem} key={0}>
            <i className={styles.icon}>
              <CalendarDay24Regular />
            </i>
            <span className={styles.itemText}>{claimLog.date}</span>
          </li>
        </ul>
      </div>
      <div className={styles.actions}>
        <Button
          appearance="primary"
          size="large"
          icon={<Open16Regular />}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL_GOS}/claim/${claimLog.claimID}?readOnly=true`,
              "_blank",
              "location=yes,height=570,width=520,scrollbars=yes,status=yes"
            );
          }}
          className={styles.actionButton}
        >
          {t("oac.openInGOS.action")}
        </Button>
        <Button
          appearance="secondary"
          size="large"
          icon={<Add16Regular />}
          onClick={createClaim}
          className={styles.actionButton}
        >
          {t("oac.createAnyway.action")}
        </Button>
      </div>
    </div>
  );
};
