import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

/* global process, console */

export const reactPlugin = new ReactPlugin();
// Telemetry setup
// For more details see https://github.com/microsoft/ApplicationInsights-JS/tree/master/extensions/applicationinsights-react-js
export const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableAutoRouteTracking: true,
  },
});

try {
  appInsights.loadAppInsights();
} catch (error) {
  console.error("Failed to load Application Insights:", error);
}
